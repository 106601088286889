@import "./mixins";

// INPUT FIELDS

.form-group {
  display: flex;
  flex-direction: column;
  font-size: 1em;
  margin-bottom: 20px;
  justify-content: flex-start;

  @media (max-width: 767.9px) {
    width: 100%;
  }

  input {
    height: 40px;
  }

  input,
  textarea {
    padding: 11px 15px;
    line-height: inherit;
    font-style: normal;
    color: var(--black);
    border-radius: 2px;
    width: -webkit-fill-available;
    max-width: 100%;

    @media (max-width: 420px) {
      min-width: 70%;
    }

    &::placeholder {
      font: inherit;
      font-size: 14px;
      font-weight: 400;
      line-height: inherit;
      color: var(--gray);
    }

    &:read-only:not([type="file"]),
    &:read-only:not([type="radio"]),
    &:read-only:not([type="checkbox"]) {
      cursor: not-allowed;
    }

    &:disabled {
      background-color: var(--disabled-gray);
      color: var(--gray);
    }
  }

  // input[type="password" i] {
  //     -webkit-text-security: disc !important;
  // }

  .form-group-label {
    color: var(--black);
    padding-bottom: 4px;
    font-size: 14px;
    font-weight: 600;

    &.required {
      &::after {
        content: "*";
        color: var(--danger);
        margin-left: 3px;
      }
    }

    .question {
      width: 15px;
      height: 15px;
      margin-left: 10px;
      border-radius: 50%;
      border: 1px solid var(--black);
      text-align: center;
      display: inline-block;
      font-size: 10px;
    }
  }

  .input-left {
    height: 40px;
    text-align: center;
    width: 150px;
  }

  .input-wrapper {
    border-radius: 2px;
    border: 1px solid var(--light-gray);

    @media (max-width: 579.5px) {
      width: 100%;
    }

    &.error {
      border: 1px solid var(--danger);
    }
    &.disabled {
      background-color: var(--disabled-gray);
    }

    &.no-hover-color,
    &.no-hover-color:hover &.no-hover-color:focus-within {
      border: 1px solid var(--light-gray) !important;

      &.error {
        border: 1px solid var(--danger) !important;
      }

      &.disabled {
        background-color: var(--disabled-gray) !important;
      }
    }
  }

  .password-input-wrapper img {
    cursor: pointer;
  }

  .input-text-error {
    display: flex;
    align-items: center;
    padding-top: 7px;
    font-size: 0.9em;
    color: var(--danger);
    font-family: "gilroy-regular";
  }

  &:hover,
  &:focus-within {
    .form-group-label,
    .info-text {
      color: var(--secondary);

      .question {
        color: var(--secondary);
      }
    }
  }

  &:disabled {
    background-color: var(--light-gray);
    border: 1px solid var(--light-gray);
  }

  &.small-form-group {
    input {
      padding: 0.4rem 0.8rem;
      font-size: 1.1rem;
      background: unset !important;

      &::-webkit-calendar-picker-indicator {
        margin-left: 0px !important;
      }
    }
  }

  &.secondary-input {
    .input-wrapper {
      border: 1px solid var(--secondary-accent-gamma);

      &.no-hover-color,
      &.no-hover-color:hover,
      &.no-hover-color:focus-within {
        border: 1px solid var(--secondary-accent-gamma) !important;
      }

      &.error {
        border: 1px solid var(--danger);
      }
      &.disabled {
        background-color: var(--disabled-gray);
      }
    }
  }

  &.small-input {
    input {
      min-width: 40%;
    }
  }
}

.form-group:hover,
.form-group:focus-within {
  .input-wrapper,
  .input-wrapper.error {
    border: 1px solid var(--secondary);
  }
}

.new-report-input {
  .input-wrapper {
    height: 43px !important;
  }
}

.show__password svg path {
  fill: var(--secondary) !important;
}

.header__form {
  .form-group {
    min-height: 38px !important;
  }
}

.table__body {
  .form-group {
    margin-bottom: 0px;

    .input-wrapper {
      border: none !important;
    }

    .form-group-select,
    .form-group-select_error {
      [class*="-control"] {
        border: none !important;
      }
    }
  }
}

.react-tel-input {
  .form-control {
    border: none !important;
    height: 41px !important;
  }
  .flag-dropdown {
    background-color: transparent !important;
    border: none !important;
  }
}

// FILE INPUTS

.file-upload {
  .file-input {
    background-color: var(--white);
    color: var(--gray);
    padding: 0px !important;
    font-size: 14px;
  }

  .file-input::-webkit-file-upload-button {
    display: none;
  }

  .file-input::before,
  .file-input:hover::before,
  .file-input:active::before,
  .input-left {
    content: "Upload File";
    display: inline-block;
    background: var(--secondary-accent-zeta);
    border-radius: 4px 0px 0px 4px;
    color: var(--gray);
    padding: 12px 20px;
    margin: 0 10px 0 0;
    outline: none;
    white-space: nowrap;
    height: 40px;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    font-weight: 700;
  }
}

.input--left {
  background: var(--secondary-accent-zeta);
  border-radius: 4px 0px 0px 4px;
  color: var(--gray);
  padding: 12px 20px;
  outline: none;
  white-space: nowrap;
  height: 40px;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 13px !important;
}

// SELECT FIELDS

.small__drop {
  .form-group-select__menu {
    height: 180px;
  }
}

.form-group-select,
.form-group-select_error,
.form-group-select--is-multi,
.form-group-select--is-multi_error {
  width: 100% !important;

  [class*="-control"] {
    color: inherit;
    margin-top: 0 !important;
    padding: 0 !important;
    border: none;
    border-radius: 2px !important;
    box-shadow: none !important;
    min-height: 42px;

    svg {
      @include rot-0;
    }

    &:hover,
    &:focus-within svg path {
      fill: var(--secondary) !important;
    }

    &:focus-within svg {
      @include rot-180;
    }
  }

  [class*="__menu"] {
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  [class*="-option"] {
    background-color: var(--white) !important ;
    color: unset !important;
  }
  [class*="-option"]:hover {
    background-color: var(--secondary) !important ;
    color: var(--white) !important;
  }
  [class*="-container"]:focus {
    border: none !important;
  }
  [class*="-control"],
  [class*="__indicators"],
  [class*="__value-container"],
  [class*="__input-container"],
  input[class*="__input"] {
    height: 40px !important;
    margin: 0px !important;

    &::placeholder {
      font: inherit;
      font-size: 14px;
      font-weight: 400;
      line-height: inherit;
      color: var(--gray);
    }
  }

  span {
    display: none;
  }

  .form-group-select__indicator,
  .form-group-select--is-multi__indicator {
    padding: 0 10px !important;

    svg {
      height: 25px !important;
      width: 25px !important;

      path {
        fill: var(--light-gray) !important;
      }
    }
  }

  .form-group-select__control--is-disabled {
    background-color: var(--disabled-gray);

    .form-group-select__single-value--is-disabled {
      color: var(--gray);
    }

    svg {
      height: 25px !important;
      width: 25px !important;

      path {
        fill: var(--light-gray) !important;
      }
    }
  }

  .form-group-select__placeholder,
  .form-group-select__single-value {
    margin-top: 5px;
    font-size: 14px;
  }

  .form-group-select__placeholder {
    color: var(--gray);
  }
}

.secondary-select {
  .input-wrapper {
    border-color: var(--secondary-accent-gamma);

    .form-group-select,
    .form-group-select--is-multi {
      [class*="-control"] {
        border: none !important;

        svg path {
          fill: var(--secondary-accent-gamma) !important;
        }
      }

      &:hover,
      &:focus-within {
        [class*="-control"] svg path {
          fill: var(--secondary) !important;
        }
      }
    }
  }
}

.form-group-select_error,
.form-group-select--is-multi_error {
  [class*="-control"] {
    border: none !important;
  }

  [class*="__indicators"] svg path {
    fill: var(--danger) !important;
  }

  &:hover,
  &:focus-within {
    [class*="-control"] svg path {
      fill: var(--secondary) !important;
    }
  }
}

// SMALL SELECT

.MuiOutlinedInput-input {
  padding: 6px 6px 7px 8px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}
.MuiOutlinedInput-notchedOutline {
  top: -2px !important;
  border: 1px solid var(--gray) !important;
}
.MuiFormControl-root {
  min-width: 100% !important;
  display: inline-block;
}
.MuiSelect-icon {
  top: unset !important;
}
.MuiInputBase-root {
  font-size: unset !important;
}
.MuiInputBase-input {
  height: unset !important;
}
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid var(--gray) !important;
  background-color: unset !important;
}

.MuiSelect-select {
  &:focus {
    background: unset !important;
  }
}

// DATE SELECT

.date-select {
  border: 1px solid var(--light-gray);
  border-radius: 2px;

  .calender-icon-container {
    background-color: var(--faint-gray);
    border-right: 1px solid var(--light-gray);
    padding: 10px 0px;
    height: 100%;
  }

  .MuiOutlinedInput-input {
    padding: 6px 6px 7px 8px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    width: 100% !important;
  }
  .MuiOutlinedInput-notchedOutline {
    top: -2px !important;
    border: 1px solid transparent !important;
  }
  .MuiFormControl-root {
    min-width: 100% !important;
    display: inline-block;
  }
  .MuiSelect-icon {
    top: unset !important;
  }
  .MuiInputBase-root {
    font-size: unset !important;
    width: 100% !important;

    .MuiSvgIcon-root {
      margin: 0px 10px !important;
    }
  }
  .MuiInputBase-input {
    height: unset !important;
  }
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid transparent !important;
    background-color: unset !important;
  }

  .MuiSelect-select {
    text-overflow: ellipsis;

    &:focus {
      background: unset !important;
    }
  }
}

// .MuiPaper-elevation8 {
//   box-shadow: 0px 3px 0px -10px rgb(0 0 0 / 20%),
//     0px 0px 20px -8px rgb(0 0 0 / 14%), 0px 3px 15px 0px rgb(0 0 0 / 12%) !important;
// }

// CHECKBOXES AND RADIOS

.custom-radio,
.custom-check-box {
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  position: relative;
  width: 100%;

  input {
    -moz-appearance: none;
    -o-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    box-sizing: border-box;
    cursor: pointer !important;
    outline: none;
    transition-duration: 0.3s;
  }

  label {
    color: inherit;
    line-height: 17px;
    font-family: "gilroy-semibold";
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    margin-left: 10px;
  }
}

// RADIOS

.custom-radio {
  margin-bottom: 5px;

  input[type="radio"] {
    border-radius: 50%;
    border: 0.5px solid var(--secondary);
    height: 18px;
    padding: 4px;
    min-width: 18px;
    display: -ms-grid;
    display: grid;
    align-content: center;
    justify-content: center;
    appearance: none;
    background-color: var(--white);
    transform: translateY(-0.075em);

    &::before {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      box-shadow: inset 1em 1em var(--white);
      -o-transition: 700ms transform ease-in-out;
      -moz-transition: 700ms transform ease-in-out;
      -webkit-transition: 700ms transform ease-in-out;
      transition: 700ms transform ease-in-out;
    }

    &:checked::before {
      box-shadow: inset 1em 1em var(--secondary);
    }
  }

  &:hover {
    color: var(--secondary);
  }
}

// CHECKBOXES

.custom-check-box {
  margin-bottom: 25px;

  input {
    background-color: var(--black-2);
    border: 0.5px solid var(--secondary);
    border-radius: 2px;
    height: 16px;
    padding: 0;
    min-width: 16px;

    &:checked {
      background-color: var(--secondary);
    }
  }

  label:hover {
    color: var(--secondary);
  }

  &.secondary {
    input {
      background-color: var(--white);
      border: 0.5px solid var(--secondary);

      &:checked {
        background-color: var(--secondary);
      }
    }

    label {
      color: var(--black);
    }

    &:hover {
      label {
        color: var(--secondary);
      }
    }
  }

  &.auth {
    input {
      background-color: var(--white);
      border: 0.5px solid var(--gray) !important;

      &:checked {
        background-color: var(--secondary) !important;
      }
    }

    label:hover {
      color: var(--gray);
    }
  }
}

.sub-checkbox {
  margin-bottom: 0px !important;

  input {
    height: 14px !important;
    width: 14px !important;
  }

  label {
    font-size: 12px;
    font-weight: 500px;
  }
}

// DATE PICKER

.date-icon-container {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: var(--secondary);
}

.input-container-wrapper {
  .input-wrapper:hover,
  .input-wrapper:focus-within {
    border: 1px solid var(--secondary);

    input {
      border: none !important;
    }
  }

  .disabled {
    border: 1px solid var(--light-gray);

    .date-icon-container {
      background-color: var(--disabled-gray);

      svg mask {
        fill: var(--disabled-gray);
      }
    }
  }
  .react-datepicker {
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
  }

  .react-datepicker__close-icon::after {
    background-color: var(--secondary);
  }

  &.date-input {
    background-color: var(--white);
  }

  &.date-input:hover,
  &.date-input:focus-within {
    cursor: pointer;

    svg path {
      fill: var(--secondary);
    }
  }
}

.date-input--bordered {
  .date-icon-container {
    background-color: var(--white);
  }
}

.inline-datepicker {
  .input-wrapper,
  .input-wrapper:hover {
    border: 1px solid transparent;

    .date-icon-container {
      display: none;
    }
  }
}

.date--select-date-picker {
  position: fixed;
  right: 15%;
  top: 27%;
  z-index: 2;
}

// UNDERLINED INPUTS

.Select.is-open {
  z-index: 1000 !important;
}

.input--underlined {
  .input-wrapper {
    border: none;
    border-radius: 0px !important;
    border-bottom: 1px solid var(--light-gray);

    &.error {
      border: none;
      border-bottom: 1px solid var(--danger);
    }
    &.disabled {
      border: none;
    }

    &:hover,
    &:focus-within {
      border: none;
      border-bottom: 1px solid var(--secondary) !important;
    }
  }

  .form-group-select,
  .form-group-select--is-multi {
    [class*="-control"] {
      border: none;
      border-radius: 0px !important;
      border-bottom: 1px solid var(--light-gray);

      &:hover,
      &:focus-within {
        color: var(--secondary) !important;
        border-color: var(--secondary) !important;
      }
    }
  }

  .form-group-select_error,
  .form-group-select--is-multi_error {
    [class*="-control"] {
      border: none !important;
      border-radius: 0px !important;
      border-bottom: 1px solid var(--danger) !important;

      &:hover,
      &:focus-within {
        color: var(--secondary) !important;
        border-color: var(--secondary) !important;
      }
    }
  }

  .date-icon-container {
    background-color: var(--white);

    svg path {
      fill: var(--black);
    }
  }
}

// FILE UPLOAD

.form-group .file-upload {
  &.input-wrapper {
    border: none !important;
  }
}

// DROPDOWN

.MuiPaper-elevation8 {
  box-shadow: 0px 0px 3px var(--light-gray) !important;
  font-size: 14px;
}

.MuiListItem-button {
  font-size: 14px !important;

  &:hover {
    background-color: var(--disabled-gray) !important;
  }
}

// .dropdown {
//     margin-top: 45px;
//     position: fixed;
//     z-index: 1400 !important;
// }

#profile-dropdown--menu {
  margin: 50px 0 0 15px;

  .MuiPopover-paper {
    height: 460px;
    width: 350px;
    font-family: "gilroy-regular";
  }
}

.profile-dropdown--option {
  padding: 1rem 0;
  flex: 0 0 auto;
  font-family: "gilroy-regular";
  width: 100%;

  @include flex__start;
}

// Input Cliipboard

.icon-right {
  background: var(--disabled-gray);
  border-radius: 3px;
  padding: 5px;
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: var(--gray);
  }
}

//OTP INPUTS

.otp__input {
  margin: 0 0 3.3rem 0;

  div {
    color: var(--gray);
  }

  input {
    border: 1px solid var(--gray) !important;
    width: 40px !important;
    border-radius: 2px !important;
    border: 1px solid var(--light-gray) !important;
    color: var(--gray);
    margin: 0 10px;
    height: 40px;
    font-family: "gilroy-regular";
    font-size: 2rem;

    @media (max-width: 575.98px) {
      font-size: 2.5rem;
      height: 50px !important;
      width: 36px !important;
    }

    &.with-separator {
      div {
        padding-right: 8px;
      }

      input {
        font-size: 2rem;
        height: 40px;
        width: 40px !important;
      }
    }
  }
}

// FILTER INPUTS

.table-filter {
  #filter-form {
    .form-group {
      .form-group-label {
        color: var(--gray);
        font-weight: 600;
      }

      input {
        font-size: 14px;
        height: 30px;
        padding: 8px 10px;
      }

      .date-input {
        .input-wrapper {
          background-color: var(--faint-gray);

          input {
            background-color: var(--faint-gray);
          }

          svg {
            fill: var(--faint-gray) !important;
          }
        }
      }

      .custom-check-box {
        margin-bottom: 0px;
        padding: 10px;

        &:hover {
          background-color: var(--faint-gray);
        }

        input {
          background-color: var(--white);
          border: 1px solid var(--surface-gray);
          height: 16px;
          padding: 0;
          width: 16px;

          &:checked {
            background-color: var(--secondary);
          }
        }

        label {
          font-family: "gilroy-regular";
        }
      }

      &:hover,
      &:focus-within {
        .form-group-label {
          color: var(--secondary);
        }
      }
    }
  }
}

// TOGGLE SWITCH

.toggle-switch {
  .switch-title {
    color: var(--black);
    font-family: "gilroy-semibold";
    font-size: 14px;
    font-weight: 500;
  }
}

.custom__date {
  position: absolute;
  top: 40px;
  z-index: 9999;
  right: 0;
  padding: 20px 10px 0 0;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2rem !important;
  font-size: 1.5em;
}

// .react-datepicker,
.react-datepicker__current-month {
  padding: 0 14px 14px !important;
  font-size: 1.5em;
}
.react-datepicker__month-dropdown-container,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__year-dropdown-container,
.react-datepicker__year-dropdown-container--select {
  padding: 14px !important;
  font-size: 1.5em !important;
}

.react-datepicker__day,
.react-datepicker__day-name {
  padding: 3px 14px !important;
}

.react-datepicker,
.react-datepicker * {
  border: 0px !important;
  background-color: var(--white) !important;
  color: var(--gray) !important;

  &:hover {
    background-color: var(--white) !important;
    z-index: 99999999 !important;
  }

  .react-datepicker__day--selected {
    // background-color: var(--secondary) !important;
    color: var(--secondary) !important;
    font-family: "gilroy-bold";
    font-size: 1.6rem;
  }
}

.react-datepicker svg *,
.react-datepicker__day-name {
  color: var(--gray) !important;
}
.react-datepicker__day--outside-month {
  color: transparent !important;
  pointer-events: none;
}

.react-datepicker__day--disabled {
  color: #ddd !important;
}

/* Custom Dropdown Select */
.custom_select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 47px;
  line-height: 30px;

  .selected {
    border-radius: 2px;
    border: 1px solid var(--light-gray);
    color: var(--gray-3);
    cursor: pointer;
    user-select: none;
    height: 40px;
    display: flex;
    // justify-content: space-between;
    align-items: center;

    input {
      border: none !important;
      height: 100% !important;
    }

    &:hover {
      border: 1px solid var(--secondary);
    }

    &.open {
      border-radius: 4px 4px 0px 0px;

      svg {
        transform: rotate(180deg);
        transition: linear 0.3s;
      }
    }
  }
  .items {
    background: var(--white);
    color: var(--black) !important;
    border-radius: 0px 0px 6px 6px;
    overflow: hidden;
    border-right: 1px solid var(--light-gray);
    border-left: 1px solid var(--light-gray);
    border-bottom: 1px solid var(--light-gray);
    position: absolute;
    left: 0;
    right: 0;
    z-index: 99;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
    max-height: 230px;
    min-height: 180px;
    position: relative;
    padding-top: 5px;

    .option__list {
      color: var(--black-2);
      padding: 0rem 1em !important;
      cursor: pointer;
      user-select: none;
      font-size: 1.2rem;

      &:hover {
        background-color: var(--secondary);
        color: var(--white);
      }
    }

    .options {
      max-height: 180px;
      overflow-y: scroll;
      padding-bottom: 50px;
    }

    .custom_select_footer {
      border-top: 1px solid var(--light-gray);
      background: var(--white);
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 0.4rem 2rem;
      cursor: pointer;
      color: var(--secondary);
      font-family: "gilroy-bold";
      font-size: 1.4rem;
    }
  }
}

.custom-slider {
  .input-wrapper {
    border: 0 !important;
  }
  .slider-input {
    max-width: 98px !important;
    min-width: 98px !important;
    background: var(--faint-gray);
    padding: 8px 5px;
    height: 36px;
    border: 1px solid #ebebf5;
    border-radius: 2px;
  }

  .slider-to {
    font-weight: 700;
    font-size: 11px;
    text-align: center;
    letter-spacing: 0.3px;
    color: var(--gray);
  }
}

.range-label {
  color: #82898f;
  font-weight: 600;
  padding-bottom: 4px;
  font-size: 14px;
}
.range-label-to {
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  color: #b2b6ba;
}


.input-wrapper {
    .color-holder {
        border-right: 1px solid var(--light-gray);
        width: 40px;
        height: 40px;
    }

    &:focus-within {
        .color-holder {
            border-right: 1px solid var(--primary);
        }
    }
}