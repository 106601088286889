@import "./fonts.css";
@import "./variables.css";

html {
  box-sizing: border-box;
  font-size: 56.25%;
  overflow-x: hidden;
}

@media (min-width: 1024px) {
  html {
    font-size: 62.5%;
  }
}

html,
body {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  position: relative;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  color: var(--main-text);
  font: normal 12px "gilroy-regular", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  border: 0;
  outline: 0;
  font: inherit;
  font-size: 100%;
  line-height: inherit;
  vertical-align: baseline;
  margin: 0;
}

a,
a:hover {
  text-decoration: none;
}

a {
  color: unset;
}

ul {
  padding: 0;
}

li {
  list-style: disc;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-size: 100%;
  font-family: inherit;
  line-height: 1.15;
}

button,
input {
  overflow: visible;
}

.rounded {
  border-radius: 6px !important;
}

.pointer {
  cursor: pointer;
}

.faded {
  opacity: 0.5;
}

.flex__column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
}

.flex__center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex__between {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex__end {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex__start {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-webkit-autofill:checked {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
}
.rel {
  position: relative;
}

.abs {
  position: absolute;
}

.bg-secondary-accent-theta {
  background-color: var(--secondary-accent-theta);
}
.bg--primary {
  background-color: var(--primary);
}
.bg--danger-transparent {
  background-color: var(--danger-transparent);
}
.bg--danger-transparent .MuiOutlinedInput-root {
  background: var(--white) !important;
}
.bg--success-transparent {
  background-color: var(--success-transparent);
}
.bg--success-transparent .MuiOutlinedInput-root {
  background: var(--white) !important;
}

.py-50 {
  padding: 50px 0;
}

.p-5 {
  padding: 25px;
}

.rounded-card {
  border-radius: 16px;
}

.progress-bar {
  background-color: var(--secondary);
}
.progress {
  border-radius: 1rem;
  background-color: #ffffff;
}

.blur {
  color: transparent;
  text-shadow: 0 0 14px var(--white);
}
